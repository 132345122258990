.spa--banner {
    background-image: url(../img/spa_banner.jpg);
    width: 100%;
    height: 35vh;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top;
  }

  .laser {
    display: flex;
    flex-direction: column;
  }

 

  @media (min-width: $desktopWidth) {
    .laser--content {
      padding: 0 50px;
    }

    .reverse {
      flex-direction: row-reverse;
    }
  
  }