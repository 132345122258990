.footer {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  // padding: 10px;
  border-top: 1px solid black;
  margin: 25px 15px;
  padding-top: 10px;

  &__logo--container {
    width: 75px;
    margin-bottom: 25px;
  }
  &__logo--container img {
    width: 100%;
  }

  &__nav--desktop {
    display: none;
  }

  &__nav--mobile {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
  }

  &__nav--item {
    text-align: center;
    margin-bottom: 20px;
  }

  &__nav--item img {
    width: 35%;
  }
  &__nav--item:last-child img {
    width: 45%;
  }

  &__nav--item a {
    text-decoration: none;
    color: black;
  }
  &__copyright {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    flex-direction: column;
    font-family: secondaryFont;
    font-size: 15px;
  }

  &__copyright span {
    padding: 5px;
  }
  
  &__copyright img {
    width: 15px;
  }

  &__powered img {
    width: 25px;
  }
  &__powered a {
    text-decoration: none;
    color: black;
    font-family: secondaryFont;
  }
}

@media (min-width: $desktopWidth) {
  .footer {
    &__nav--mobile {
      justify-content: space-around;
    }

    &__logo--container {
      width: 125px;
      margin-bottom: 25px;
    }
    &__logo--container img {
      width: 100%;
    }
    &__nav--item img {
      width: 30px;
    }
    &__nav--item:last-child img {
      width: 40px;
    }
  }
}
