.contact {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;

  &__title {
    font-family: secondaryFont;
    text-transform: uppercase;
    font-size: 29px;
    padding: 20px 20px 0 20px;
  }

  &__form {
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 0 25px;
    padding: 15px;
    background-color: rgba(37, 149, 190, 0.312);
  }

  &__form--item {
    display: flex;
    flex-direction: column;
    padding: 10px;
    font-family: secondaryFont;
    text-transform: uppercase;
  }
&__form--btn {
  background-color: white;
}
  

  &__form--item label {
    font-size: 10px;
    padding:5px ;
  }

  span {
    font-size: 10px;
  }



}
