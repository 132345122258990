.under {
    background-image: url(../img/IMG_6573.JPG);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    max-width: 1200px;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
}

.under div  {
    background-color: rgba(255, 255, 255, 0.611);
    height: 40vh;
    border-radius: 15px;
    padding: 50px;
    font-family: secondaryFont;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.under h1 {
    font-family: secondaryFont;
    font-size: 42px;
    margin-bottom: 20px;
}
