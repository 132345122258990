.header {
  font-family: secondaryFont;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  width: 100%;
  justify-items: center;
  box-shadow: rgba(0, 0, 0, 0.19) 0px 12px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
  height: 70px;


    &__logo img {
      width: 75%;
    }
    &__logo a {
      text-align: center;
    }

    &__logo {
      display: flex;
      position: relative;
      justify-content: center;
      align-items: center;
      height: 150px;
      width: 150px;
      background-color: white;
      border-radius: 50%;
      z-index: 1;
    }
    &__logo::before {
      content: "";
      position: absolute;
      box-shadow: rgba(0, 0, 0, 0.19) 0 8px 5px 4px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
      top: 50%;
      border-radius: 0 0 50% 50% / 0 0 100% 100%;
      background-color: white;
      height: 75px;
      width: 150px;
      z-index: -1;
    }

    &__nav--btn {
      width: 40px;
      height: 40px;
      position: relative;
      z-index:4;
      overflow: hidden;
      top: 15px;
    }

    &__nav--btn span {
      width: 30px;
      height: 2px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: #222222;
      transition: all 0.5s;
    }

    &__nav a {
      color: black;
      text-decoration: none;
    }

    &__nav--btn span:nth-of-type(2) {
      top: calc(50% - 5px);
    }
    &__nav--btn span:nth-of-type(3) {
      top: calc(50% + 5px);
    }


    &__nav--btn.active span:nth-of-type(1) {
      display: none;
    }
    &__nav--btn.active span:nth-of-type(2) {
      top: 50%;
      transform: translate(-50%, 0%) rotate(45deg);  
    }
    &__nav--btn.active span:nth-of-type(3) {
      top: 50%;
      transform: translate(-50%, 0%) rotate(-45deg); 
    }

    &__nav {
      position: fixed;
      display: grid;
      grid-template-rows: repeat(5, 1fr);
      justify-content: center;
      left: 0;
      width: 100%;
      height: 50vh;
      background: rgba(255, 255, 255,);
      transform: translateX(-100%);
      transition: transform 0.8s; 
      z-index: 2;
      padding: 70px 20px;
      box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
    }

    &__nav--item {
      font-size: 18px;
    }
    &__desktop--item{
      text-decoration: none !important;
      color: black !important;
    }

    &__nav.active {
      transform: translateX(0);
    }

    &__btn {
      padding: 5px 0;
    }

    &__desktop--item {
      display: none;
    }
    
}


@media (min-width: $desktopWidth) {
.header {
  grid-template-columns: repeat(7, 1fr);

  &__desktop--item {
    display: block;
    margin-top: 25px;
  }

  &__nav {
    justify-content: start;
  }

  &__nav--item {
    position: relative;
    left: 100%;
  }
}



}