
.divider {
  width: 50%;
  margin-bottom: 20px;
  margin: auto;
}

a.text:active, /* активная/посещенная ссылка */
a.text:hover,  /* при наведении */
a.text {
  text-decoration: none!important;
}

.btn--appointment,
.myBtn {
  display: block;
  font-family: secondaryFont;
  border-radius: 20px;
  border: none;
  padding: 10px;
  margin: auto;
  margin-top: 20px;
  background-color: white;
  color: black;
}

.myBtn {
  margin-bottom: 25px;
}

.input {
  outline: none;
  border: none;
}

.textarea {
  outline: none;
  border: none;
}


@media (min-width: $desktopWidth) {
  .container {
    max-width: 1200px;
    margin: auto;
  }

  .divider {
    width: 20%;
    margin-bottom: 20px;
  }
  
  }
