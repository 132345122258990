  .dropdown {
    width: 80px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    // margin: 50px;
    padding-top: 15px;
    z-index: 1;
  }
  
  .dropdown * {
    box-sizing: border-box;
    margin: 5px;
  }
  
  .select {
    color: #000;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  
  .caret {
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 6px solid #000;
    transition: 0.3s;
  }
  
  
  .caret-rotate {
    transform: rotate(180deg);
  }
  
  .menu {
    list-style: none;
    color: #000;
    position: absolute;
    top: 40px;
    opacity: 0;
    display: none;
    transition: 0.9s;
    z-index: 1;
    background-color: #fff;
    padding: 25px;
  }
  
  .menu li {
    cursor: pointer;
  }
  
   
  .menu-open {
    display: block;
    opacity: 1;
  }






  button {
    display: flex;
    align-items: center;
    border: 0;
    background: transparent;
    cursor: pointer;
    font-size: 18px;
    font-family: secondaryFont;
  }
  
  .split-button {
    position: relative;
    align-items: flex-start;
    display: flex;
    align-content: flex-start;
    cursor: pointer;
    font-size: 18px;
    font-family: secondaryFont;
  }
  
  .split-button > button:nth-child(1) {
    padding: 0;
  }
  
  .split-button > button:nth-child(2) {
    width: 60px;
  }
  
  .main-menu {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    position: absolute;
    left: -40%;
    top: 5vh;
    z-index: 1;
    width: 70vw;
    translate: 30px 0;
    opacity: 0;
    visibility: hidden;
    border-radius: 8px;
    background: #ffffff;
    transition: 0.4s;
  }

  .main-menu img {
    display: none;
  }
  
  .main-menu.open {
    translate: 0 0;
    opacity: 1;
    visibility: visible;
  }
  
  .main-menu div {
    display: flex;
    flex-direction: column;
    gap: 10px;
    border: 0;
    border-radius: 0;
    width: 100%;
    height: 56px;
    padding: 0 18px;
  }
  
  .main-menu > button:hover {
    background: #00000042;
  }
  
  // .chevron {
  //  width: 20px;
  //  height: 20px;
  // }
  


  @media (min-width: $desktopWidth) {

    .main-menu {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      box-shadow: rgba(0, 0, 0, 0.19) 4px 8px 5px 4px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
      padding: 25px;
      // left: 130%;
      // top: -20vh;

    }

  //     .chevron {
  //       position: relative;
  //       left: 12vw;
  // }
  

  .main-menu img {
    display: block;
  }
  

  .main-menu div {
    height: 400px;
    width: 250px;
    padding: 50px 30px;
    border-radius: 5px;

  }
    
    }









