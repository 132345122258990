.services {
  text-align: center;

  &__title {
    font-family: secondaryFont;
    text-transform: uppercase;
    font-size: 22px;
    width: 100%;
    padding: 20px 20px 0 20px;
  }

  &__cards {
    position: relative;
    display: flex;
    align-items: center;
    padding: 5px;
    height: 65vh;
    width: 100%;
    text-align: center;
  }
  &__cards--container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__card--image-box {
    height: 100%;
    width: 100%;
  }

  &__card--content-box {
    padding: 15px;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.5);
    position: absolute;
    // bottom: 15%;
    font-family: secondaryFont;
    text-transform: uppercase;
    font-size: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    left: 0;
    right: 0;
    z-index: 1;
  }
  &__card--content-box p {
    font-family: secondaryFont;
    text-transform: uppercase;
    font-size: 12px;
    line-height: 1.7;
    margin-top: 25px;
  }
  &__card--content-box a {
    display: block;
    font-family: secondaryFont;
    font-size: 10px;
    margin-top: 20px;
    font-weight: bold;
  }
}


@media (min-width: $desktopWidth) {
  .services {
    &__cards {
      display: flex;
      justify-content: space-around;
    }
    &__cards:nth-child(2) {
      flex-direction: row-reverse;
    }

    &__cards {
      height: 85vh;
    }

    &__card--image-box {
      width: 49%;
    }

    &__card--content-box {
      position: relative;
      height: 100%;
      bottom: 0;
      padding: 15px;
      width: 49%;
      background-color: rgba(0, 0, 0, 0.216);
    }
  
  }

}

.bookingWindow {
display: block;
width: 50vw;
height: 60wh;
position:sticky;
top: 10vh;
}