.laser--banner {
    background-image: url(../img/IMG_2406.JPG);
    width: 100%;
    height: 35vh;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom;
  }

  .laser {
    display: flex;
    flex-direction: column;
  }

 

  @media (min-width: $desktopWidth) {
    .laser--content {
      padding: 0 50px;
    }

    .reverse {
      flex-direction: row-reverse;
    }
  
  }