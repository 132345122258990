@font-face {
    font-weight: 400;
    font-family: primaryFont;
    src: url("../fonts/praho-pro/PrahoProTest-Regular-BF63b7864465c7b.otf");
}

@font-face {
    font-weight: 600;
    font-family: primaryFont;
    src: url("../fonts/praho-pro/PrahoProTest-Bold-BF63b78643935eb.otf");
}

@font-face {
    font-weight: 400;
    font-family: secondaryFont;
    src: url("../fonts/Geometria-Font/Geometria.ttf");
}
@font-face {
    font-weight: 500;
    font-family: secondaryFont;
    src: url("../fonts/Geometria-Font/Geometria-Medium.ttf");
}

@font-face {
    font-weight: 600;
    font-family: secondaryFont;
    src: url("../fonts/Geometria-Font/Geometria-Bold.ttf");
}