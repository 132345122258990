// @import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");


// *:focus,
// *:active {
//   outline: none !important;
//   -webkit-tap-highlight-color: transparent;
// }
// .social {
//   display: flex;
//   height: 100%;
//   width: 100%;
//   font-family: "Poppins", sans-serif;
//   justify-content: center;
//   align-items: center;
//   background: linear-gradient(315deg, #ffffff, #d7e1ec);
//   margin-bottom: 20px;
// }

// .wrapper {
//   display: inline-flex;
//   list-style: none;
// }

// .icon {
//   position: relative;
//   background: #ffffff;
//   border-radius: 50%;
//   padding: 15px;
//   margin: 10px;
//   width: 50px;
//   height: 50px;
//   font-size: 18px;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   flex-direction: column;
//   box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
//   cursor: pointer;
//   transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
// }

// .icon a {
//   text-decoration: none !important;
// }


// .tooltip {
//   position: absolute;
//   top: 0;
//   font-size: 14px;
//   background: #ffffff;
//   color: #ffffff;
//   padding: 5px 8px;
//   border-radius: 5px;
//   box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
//   opacity: 0;
//   pointer-events: none;
//   transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
// }

// .tooltip::before {
//   position: relative;
//   content: "";
//   height: 8px;
//   width: 8px;
//   background: #ffffff;
//   bottom: -3px;
//   left: 50%;
//   transform: translate(-50%) rotate(45deg);
//   transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
// }

// .icon:hover .tooltip {
//   top: -45px;
//   opacity: 1;
//   visibility: visible;
//   pointer-events: auto;
// }

// .icon:hover span,
// .icon:hover .tooltip {
//   text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.1);
// }

// .facebook:hover,
// .facebook:hover .tooltip,
// .facebook:hover .tooltip::before {
//   background: #1877F2;
//   color: #ffffff;
// }

// .location:hover,
// .location:hover .tooltip,
// .location:hover .tooltip::before {
//   background: #fbbc05;
//   color: #ffffff;
// }

// .instagram:hover,
// .instagram:hover .tooltip,
// .instagram:hover .tooltip::before {
//   background: #E4405F;
//   color: #ffffff;
// }

// .whatsapp:hover,
// .whatsapp:hover .tooltip,
// .whatsapp:hover .tooltip::before {
//   background: #25d366;
//   color: #ffffff;
// }

// .youtube:hover,
// .youtube:hover .tooltip,
// .youtube:hover .tooltip::before {
//   background: #CD201F;
//   color: #ffffff;
// }



/*
    Auther: Abdelrhman Said
*/

@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

*:focus,
*:active {
  outline: none !important;
  -webkit-tap-highlight-color: transparent;
}


.wrapper {
  display: inline-flex;
  list-style: none;
  margin-bottom: 20px;
  font-family: "Poppins", sans-serif;
}


.icon--block {
  padding: 15px;
}

.wrapper .icon {
  position: relative;
  background: #ffffff;
  border-radius: 50%;
  padding: 15px;
  margin: 10px;
  width: 40px;
  height: 40px;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.wrapper .tooltip {
  position: absolute;
  top: 0;
  font-size: 14px;
  background: #ffffff;
  color: #ffffff;
  padding: 5px 8px;
  border-radius: 5px;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
  opacity: 0;
  left: -33%;
  pointer-events: none;
  transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.wrapper .tooltip::before {
  position: absolute;
  content: "";
  height: 8px;
  width: 8px;
  background: #ffffff;
  bottom: -3px;
  left: 50%;
  transform: translate(-50%) rotate(45deg);
  transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.wrapper .icon:hover .tooltip {
  top: -45px;
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
}

.wrapper .icon:hover span,
.wrapper .icon:hover .tooltip {
  text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.1);
}

.wrapper .facebook:hover,
.wrapper .facebook:hover .tooltip,
.wrapper .facebook:hover .tooltip::before {
  background: #1877F2;
  color: #ffffff;
}

.wrapper .location:hover,
.wrapper .location:hover .tooltip,
.wrapper .location:hover .tooltip::before {
  background: #fbbc05;
  color: #ffffff;
}

.wrapper .instagram:hover,
.wrapper .instagram:hover .tooltip,
.wrapper .instagram:hover .tooltip::before {
  background: #E4405F;
  color: #ffffff;
}

.wrapper .whatsapp:hover,
.wrapper .whatsapp:hover .tooltip,
.wrapper .whatsapp:hover .tooltip::before {
  background: #25d366;
  color: #ffffff;
}

.wrapper .telephone:hover,
.wrapper .telephone:hover .tooltip,
.wrapper .telephone:hover .tooltip::before {
  background: #4c4a4a;
  color: #ffffff;
}
.wrapper .tiktok:hover,
.wrapper .tiktok:hover .tooltip,
.wrapper .tiktok:hover .tooltip::before {
  background: #000000;
  color: #ffffff;
}

.wrapper .tiktok:hover .fab {
  color: white;
}
.tikatok i {
  color: black;
}