
.img-wrapper {
  max-width: 100%;
  height: 65vw;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}
img {
  max-width: 100%;
  max-height: 100%;
}
@media screen and (min-width: 576px) {
  .carousel-inner {
    display: flex;
  }
  .carousel-item {
    display: block;
    margin-right: 0;
    flex: 0 0 calc(100% / 3);
  }
  .img-wrapper {
    height: 21vw;
  }
}


.carousel-inner {
  padding: 1em;
}


.carousel-control-prev,
.carousel-control-next {
  width: 6vh;
  height: 6vh;
  background-color: #e1e1e1;
  border-radius: 50%;
  top: 50%;
  transform: translateY(-50%);
  opacity: 0.5;
}
.carousel-control-prev:hover,
.carousel-control-next:hover {
  opacity: 0.8;
}
