.about {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__header {
    width: 100%;
    height: 35vh;
    background-image: url(../img/IMG_5077.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
  &__title {
    font-family: secondaryFont;
    text-transform: uppercase;
    font-size: 29px;
    padding: 20px 20px 0 20px;
    text-align: center;
  }
  &__title--secondary {
    display: block;
    font-family: secondaryFont;
    text-transform: uppercase;
    font-size: 22px;
    line-height: 1.5s;
    background-color: rgba(255, 255, 255, 0.5);
    // box-shadow: 0 20px 3px rgba(255, 255, 255, 0.5), 0 -20px 3px rgba(255, 255, 255, 0.5);
    width: 100vw;
    padding: 10px 20px;
    text-align: center;
  }

  &__title--secondary:last-child {
    background-color: rgba(0, 0, 0, 0.216);
    padding: 15px 15px;
    width: 98%;
    margin: auto;
  }

  &__content {
    width: 98%;
    font-family: secondaryFont;
    text-align: center;
    margin-bottom: 30px;
    // margin: 0 15px;
  }

  &__content--image {
    margin: auto;
    width: 98%;
    height: 50vh;
    background-image: url(../img/IMG_1895.JPG);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
  &__content p {
    display: block;
    padding: 10px 10px;
  }

  // &__content:last-child {
  //   background-image: url(../img/IMG_1895.JPG);
  //   height: 60vh;
  //   background-repeat: no-repeat;
  //   position: sticky;
  //   background-size: cover;
  //   display: flex;
  //   align-items: center;
  // }
  &__content img {
    width: 98vw;
    // height: 60vh;
    position: relative;
  }

  &__card--image-box {
    height: 240px;
    overflow: hidden;
  }
  &__cards {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 5px;
    text-align: center;
    padding: 0 5px;
    margin-bottom: 35px;
  }
}

.grid-box-1 {
  order: 1;
}
.grid-box-2 {
  order: 2;
}
.grid-box-3 {
  order: 4;
}
.grid-box-4 {
  order: 3;
}

.card-img {
  width: 100%;
  object-fit: cover;
}

[data-reveal="left"] {
  clip-path: inset(0 100% 0 0);
}

[data-reveal="left"].revealed {
  animation: reveal-left 1.2s cubic-bezier(0.17, 0.97, 0.38, 1) forwards 300ms;
}

@keyframes reveal-left {
  0% {
    clip-path: inset(0 100% 0 0);
  }
  100% {
    clip-path: inset(0 0 0 0);
  }
}

[data-reveal="right"] {
  clip-path: inset(0 0 0 100%);
}

[data-reveal="right"].revealed {
  animation: reveal-right 1.2s cubic-bezier(0.17, 0.97, 0.38, 1) forwards 300ms;
}

@keyframes reveal-right {
  0% {
    clip-path: inset(0 0 0 100%);
  }
  100% {
    clip-path: inset(0 0 0 0);
  }
}

.image-box .img {
  transform: scale(1.5);
  transition: 1.2s cubic-bezier(0.17, 0.97, 0.38, 1);
}

.image-box.revealed .img {
  transform: scale(1.5);
}

@media (min-width: $desktopWidth) {
  .about {
    &__card--image-box {
      height: 60vh;
    }

    &__content:last-child {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      width: 100%;
    }
    &__content span {
      display: block;
      padding: 10px 0;
    }

    &__content img {
      width: 30vw;
      position: relative;
    }

    &__content--image {
      height: 60vh;
    }
    &__title--secondary:last-child {
      display: flex;
      width: 100%;
      align-items: center;
      height: 100%;
      font-size: 36px;
      background-color: rgba(0, 0, 0, 0.216);
    }
  }
}
