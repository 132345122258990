.team {
display: flex;
flex-direction: column;
align-items: center;

&__title {
    font-family: secondaryFont;
    text-transform: uppercase;
    font-size: 32px;
    padding: 20px 20px 0 20px;
}

&__member {
    display: flex;
   flex-direction: column;
   align-items: center;
   margin-top: 25px;
}

&__member--photo {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    transition: 2s;
    margin-bottom: 25px;
    overflow: hidden;
    display: inline-block;
}
&__member--photo img {
    width:100%;
    height:100%;
    object-fit: cover;
    transition: 2s;
}


&__member--photo.scaled {
    transform: scale(1.3);
}

&__member--name {
    font-family: secondaryFont;
    text-transform: uppercase;
    font-size: 16px;
    padding-top: 5px;
}


&__member--description {
    font-family: secondaryFont;
    text-transform: uppercase;
    font-size: 14px;
    padding: 15px;
    text-align: center;
    margin-bottom: 25px;
}

}






  