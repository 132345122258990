.contacts {
        display: flex;
        flex-direction: column;
        width: 100%;

    &__content--text {
        font-family: secondaryFont;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        
        
    }

    &__content--text span {
        margin-top: 25px;
        }
}


@media (min-width: $desktopWidth) {
    .contacts {
        flex-direction: row;
        height: 40vh;
        width: 100%;
        justify-content: center;
        

    &__content--text span {
        margin-bottom: 20px;
        font-size: 24px;
        padding: 20px;
        }

}
  }
