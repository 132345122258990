.sheet {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 0.5em;
  border-radius: 0;
  box-shadow: 2px 6px 8px 0 rgba(22, 22, 26, 0.18);
  font-size: 0.9em;
  font-family: secondaryFont;
  height: 75vh;

  &__title {
    text-transform: uppercase;
    font-size: 14px;
    text-align: center;
    font-weight: 500;
    margin-bottom: 15px;
  }

  &__body {
    margin: 8px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }

  &__content-title {
    text-align: center;
    font-weight: 500;
    margin-bottom: 5px;
  }

  &__text {
    line-height: 110%;
    text-align: center;
    margin-bottom: 10px;
    font-size: 10px;
  }
}

.news--content {
  text-transform: none;
}

.news--list {
  list-style-type: disc !important;
  margin: 5px;
}

.news--item {
  font-size: 10px;
  text-align: left;
  margin-bottom: 5px;
  text-transform: none !important;
}

.news--text {
  text-transform: none !important;
  font-size: 10px !important;
  line-height: 105% !important;
  margin-top: 0 !important;
  margin-bottom: 5px !important;
}

.card-title{
  font-size: 12px;
  font-weight: 600;
}

.card-title-secondary {
  font-size: 10px;
  font-weight: 600;
}

.news--jointTitle {
  display: flex;
  flex-direction: column;
}





@media (min-width: $desktopWidth) {
  .sheet {
    height: 100%;
  }

  .news--item {
    font-size: 14px;
    text-align: left;
    margin-bottom: 5px;
  }

  .card-title{
    font-size: 16px;
    font-weight: 600;
  }

  .news--text {
    text-transform: none !important;
    font-size: 12px !important;
    line-height: 110% !important;
    margin-bottom: 10px !important;
  }

  .card-title-secondary {
    font-size: 12px;
    font-weight: 600;
    line-height: 120%;
  }

  .news--list {
    margin: 5px 15px ;
  }
}
